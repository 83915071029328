<template>
  <div>
    <el-card class="box-card">
      <div class="title">创 建 问 卷</div>
      <div class="titleInput">
        <el-input v-model="title" placeholder="请输入问卷标题"></el-input>
      </div>
      <div class="choose">
        <div :class="[flag ? 'choose_box1' : 'choose_box']" @click="choose">
          <img src="../../../../assets/examination/icon1_qs.png" alt="" class="imgPic" />
          <p class="title1">文本导入</p>
          <p class="title2">自由编辑</p>
          <p class="title2">自动生成问卷</p>
        </div>
        <div :class="[flag1 ? 'choose_box1' : 'choose_box']" @click="choose1">
          <img src="../../../../assets/examination/icon2_qs.png" alt="" class="imgPic" />
          <p class="title1">自定义问卷</p>
          <p class="title2">自定义顺序 随心设计</p>
          <p class="title2">智能分析 在线统计</p>
        </div>
      </div>
      <div class="sub">
        <el-button type="primary" @click="next">创建问卷</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { createQs } from '@/api/examination'
import to from 'await-to'
export default {
  data() {
    return {
      title: '',
      flag: false,
      flag1: false,
    }
  },
  created() {},
  methods: {
    choose() {
      ;(this.flag = true), (this.flag1 = false)
    },
    choose1() {
      ;(this.flag1 = true), (this.flag = false)
    },
    async next() {
      if (this.title == '' || this.title.length < 6 || this.title.length > 36)
        return this.$message({
          message: '请输入6-36字问卷名称',
          type: 'warning',
        })
      if (this.flag == false && this.flag1 == false)
        return this.$message({
          message: '请选择创建类型',
          type: 'warning',
        })
      const [res, err] = await to(createQs({ surveyTitle: this.title }))
      if (err) return this.$message.warning(err.msg)
      this.$router.push({
        path: '/examination/quesManger/setting',
        query: { id: res.data },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  margin: 50px auto;
  font-size: 32px;
  text-align: center;
}
.titleInput {
  margin: 0 auto;
  width: 700px;
  height: 80px;
}
.choose {
  margin: 20px auto;
  height: 350px;
  width: 700px;
  display: flex;
  justify-content: space-between;
}
.choose_box {
  border: 1px solid #ccc;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  cursor: pointer;
}
.choose_box1 {
  border: 2px solid #ff7b33;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  cursor: pointer;
  color: #ff7b33;
}
.imgPic {
  display: block;
  margin: 20px auto;
  width: 150px;
  height: 150px;
}
.title1 {
  text-align: center;
  font-size: 18px;
}
.title2 {
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
}
.sub {
  margin: 0 auto;
  width: 110px;
  height: 140px;
}
</style>
